// FOR STEP 1 GETTING SERVICES
export const VAS_REQUEST = 'VAS_REQUEST'
export const VAS_SUCCESS = 'VAS_SUCCESS'
export const VAS_FALIURE = 'VAS_FALIURE'

// FOR STEP 2 GETTING SERVICES CATEGORIES
export const VAS_CATEGORY_REQUEST = 'VAS_CATEGORY_REQUEST'
export const VAS_CATEGORY_SUCCESS = 'VAS_CATEGORY_SUCCESS'
export const VAS_CATEGORY_FALIURE = 'VAS_CATEGORY_FALIURE'

//FOR AIRTIME PAYMENT
export const VAS_AIRTIME_REQUEST = 'VAS_AIRTIME_REQUEST'
export const VAS_AIRTIME_SUCCESS = 'VAS_AIRTIME_SUCCESS'
export const VAS_AIRTIME_FALIURE = 'VAS_AIRTIME_FALIURE'

//FOR STEP 3 GETTING SERVICES PRODUCT
export const VAS_PRODUCT_REQUEST = 'VAS_PRODUCT_REQUEST'
export const VAS_PRODUCT_SUCCESS = 'VAS_PRODUCT_SUCCESS'
export const VAS_PRODUCT_FALIURE = 'VAS_PRODUCT_FALIURE'

//FOR DATA PAYMENT
export const VAS_DATA_REQUEST = 'VAS_DATA_REQUEST'
export const VAS_DATA_SUCCESS = 'VAS_DATA_SUCCESS'
export const VAS_DATA_FALIURE = 'VAS_DATA_FALIURE'

//FOR VERIFY CABLE
export const VAS_VERIFY_REQUEST = 'VAS_VERIFY_REQUEST'
export const VAS_VERIFY_SUCCESS = 'VAS_VERIFY_SUCCESS'
export const VAS_VERIFY_FALIURE = 'VAS_VERIFY_FALIURE'

//FOR CABLE PAYMENT
export const VAS_CABLE_REQUEST = 'VAS_CABLE_REQUEST'
export const VAS_CABLE_SUCCESS = 'VAS_CABLE_SUCCESS'
export const VAS_CABLE_FALIURE = 'VAS_CABLE_FALIURE'

//FOR CABLE PAYMENT
export const VAS_UTILITY_REQUEST = 'VAS_UTILITY_REQUEST'
export const VAS_UTILITY_SUCCESS = 'VAS_UTILITY_SUCCESS'
export const VAS_UTILITY_FALIURE = 'VAS_UTILITY_FALIURE'