export const FORGOT_REQUEST = 'FORGOT_REQUEST'
export const FORGOT_SUCCESS = 'FORGOT_SUCCESS'
export const FORGOT_FALIURE = 'FORGOT_FALIURE'

//For otp confirmation

export const OTP_FORGOT_REQUEST = 'OTP_FORGOT_REQUEST'
export const OTP_FORGOT_SUCCESS = 'OTP_FORGOT_SUCCESS'
export const OTP_FORGOT_FALIURE = 'OTP_FORGOT_FALIURE'


export const NEW_FORGOT_REQUEST = 'NEW_FORGOT_REQUEST'
export const NEW_FORGOT_SUCCESS = 'NEW_FORGOT_SUCCESS'
export const NEW_FORGOT_FALIURE = 'NEW_FORGOT_FALIURE'