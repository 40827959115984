// FOR ANALYTICS
export const DASHBOARD_REQUEST = 'DASHBOARD_REQUEST'
export const DASHBOARD_SUCCESS = 'DASHBOARD_SUCCESS'
export const DASHBOARD_FALIURE = 'DASHBOARD_FALIURE'

//FOR RecentTransactions
export const RECENT_TRANSACTION_REQUEST = 'RECENT_TRANSACTION_REQUEST'
export const RECENT_TRANSACTION_SUCCESS = 'RECENT_TRANSACTION_SUCCESS'
export const RECENT_TRANSACTION_FALIURE = 'RECENT_TRANSACTION_FALIURE'

//FOR Transaction Sum
export const SUM_TRANSACTION_REQUEST = 'SUM_TRANSACTION_REQUEST'
export const SUM_TRANSACTION_SUCCESS = 'SUM_TRANSACTION_SUCCESS'
export const SUM_TRANSACTION_FALIURE = 'SUM_TRANSACTION_FALIURE'